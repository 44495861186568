export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'product',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'productCategoryId',
    label: 'field.category',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'productCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'productBrandId',
    label: 'field.brand',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'productBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    rules: 'required|max:40',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    cols: 6,
    skip: true,
    hide: true,
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'descriptionKm',
    label: 'field.descriptionKm',
    rules: 'max:2000',
    type: 'textarea',
  },
  {
    key: 'descriptionEn',
    label: 'field.descriptionEn',
    rules: 'max:2000',
    type: 'textarea',
  },
  {
    key: 'unitPrice',
    label: 'field.unitPrice',
    rules: 'required|min_value:0',
    type: 'currency',
    prefix: '$',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'saleUom',
    label: 'field.saleUom',
    rules: 'required|max:20',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'isFeature',
    label: 'field.feature',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isCalculateVolume',
    label: 'field.calculateVolume',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'oppVisible',
    label: 'field.oppVisible',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'smiaVisible',
    label: 'field.smiaVisible',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
